import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IconsPage = (props) => (
  <Layout>
    <SEO title="Icons Page" />

    <Link className="back-link" to="/">Go back to the homepage</Link>

    <h1 style={{marginTop: `50px`, textAlign: 'center'}}>Iconography</h1>
    <Img fluid={props.data.imageIconsOne.childImageSharp.fluid} />

    <div style={{maxWidth: `700px`, margin: `10px auto 30px`}}>
        <p>
            Across a number of projects I have had to create iconography. Below are icons from a few projects grouped together. I created them in either Illustrator or Affinity Designer and exported them as SVG code. After tidying that code up in the SVGO web tool I then introduced that svg code into my HTML markup where I could act on it with CSS.
        </p>
    </div>

    <div className="iconography-icons" style={{display: `flex`, flexWrap: `wrap`}}>

        <div>
            <svg id="info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10 19c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zm0-16.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5z"/>
                <path d="M10 16.7a6.7 6.7 0 1 1 0-13.4 6.7 6.7 0 0 1 0 13.4zm0-12.8c-3.4 0-6.1 2.7-6.1 6.1 0 3.4 2.7 6.1 6.1 6.1 3.4 0 6.1-2.7 6.1-6.1 0-3.4-2.7-6.1-6.1-6.1z"/>
                <path d="M10.9 12.3V9.2c-.1-.3-.4-.6-.7-.6H8.5v1.5h.6v2.1h-.6V14h3.1v-1.8h-.7zM10 8c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9z"/>
            </svg>
        </div>
        
        <div>
            <svg id="assign" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M18.7 10.9H7.4c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h11.3c.5 0 .9.4.9.9s-.4.9-.9.9z"/>
                <path d="M15.7 14.9c-.2 0-.4-.1-.6-.2-.4-.3-.4-.9-.1-1.2l3-3.3-3-3.3c-.3-.4-.3-.9.1-1.2.4-.3.9-.3 1.2.1l3.5 3.9c.3.3.3.8 0 1.2l-3.5 3.9c-.2 0-.4.1-.6.1zM11.3 12.2v6.1H1.7V1.7h9.6v6.1H13V0H0v20h13v-7.8z"/>
            </svg>
        </div>

        <div>
            <svg id="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M17.8 19.4H2.2c-1.2 0-2.2-1-2.2-2.2V8.7C0 6.6 7.5.6 10 .6c1.6 0 4.4 2.2 5.9 3.5C17.2 5 20 7.6 20 8.7v8.5c0 1.2-1 2.2-2.2 2.2zM10 2C8.2 2 1.7 7.4 1.5 8.7v8.5c0 .4.3.7.7.7h15.6c.4 0 .7-.3.7-.7V8.8C18 7.5 11.7 2 10 2zm8.5 6.8z"/>
                <path d="M18.3 18.2l-7-6.7c-.2-.2-.7-.6-1.2-.6s-1 .5-1.2.6l-7.1 6.3-.5-.6 7-6.3c.1-.1.9-.8 1.7-.8s1.7.8 1.7.8l7 6.7-.4.6z"/>
                <path d="M1.286 8.6l.355-.602 6.292 3.7-.355.604zM11.972 11.72l6.655-4.067.365.598-6.656 4.067z"/>
            </svg>
        </div>

        <div>
            <svg id="close-claim" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M20 1.5L18.5 0 10 8.4 1.5 0 0 1.5 8.4 10 0 18.5 1.5 20l8.5-8.4 8.4 8.4 1.5-1.5-8.3-8.5L20 1.5z" fill="#000000"/>
            </svg>
        </div>

        <div>
            <svg id="delete" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M13.6 20H6.4c-1.6 0-3-1.3-3-3V5.2h13.1V17c.1 1.7-1.3 3-2.9 3zM4.8 6.6V17c0 .9.7 1.6 1.6 1.6h7.2c.9 0 1.6-.7 1.6-1.6V6.6H4.8z"/>
                <path d="M17.6 6.6H2.4v-.9c0-1.2 0-2.5.7-3.2.3-.4.8-.5 1.3-.5h11.1c.6 0 1 .2 1.4.5.7.7.7 2 .7 3.2v.9zM3.7 5.9zm0-.7h12.6c0-.8-.1-1.5-.3-1.8-.1-.1-.2-.1-.4-.1H4.4c-.2 0-.3.1-.4.1-.3.3-.3 1.1-.3 1.8z"/>
                <path d="M13 3.3H7V0h6v3.3zM8.4 2h3.3v-.7H8.4V2zM6.9 17.4c-.4 0-.7-.3-.7-.7V8.5c0-.4.3-.7.7-.7.4 0 .7.3.7.7v8.2c-.1.4-.4.7-.7.7zM10 17.4c-.4 0-.7-.3-.7-.7V8.5c0-.4.3-.7.7-.7s.7.3.7.7v8.2c0 .4-.3.7-.7.7zM13.1 17.4c-.4 0-.7-.3-.7-.7V8.5c0-.4.3-.7.7-.7.4 0 .7.3.7.7v8.2c0 .4-.3.7-.7.7z"/>
            </svg>
        </div>

        <div>
            <svg id="template-letters" class="c-icons__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M15.3 0H4.7C3.5 0 2.5 1 2.5 2.3v15.5c0 1.2 1 2.3 2.3 2.3h10.5c1.2 0 2.3-1 2.3-2.3V2.3C17.5 1 16.5 0 15.3 0zm.7 18.4H4V1.7h12v16.7z"/>
                <path d="M5.7 4h3.7v.7H5.7zM5.7 5.7h3.7v.7H5.7zM5.7 7.4h6.7v.7H5.7zM5.7 9h6.7v.7H5.7zM5.7 10.7h8.4v.7H5.7zM5.7 12.4h8.4v.7H5.7zM5.7 14.1h8.4v.7H5.7zM5.7 15.7h8.4v.7H5.7z"/>
            </svg>
        </div>

        <div>
            <svg id="nihl-articles" class="c-icons__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M15.3 0H4.7C3.5 0 2.5 1 2.5 2.3v15.5c0 1.2 1 2.3 2.3 2.3h10.5c1.2 0 2.3-1 2.3-2.3V2.3C17.5 1 16.5 0 15.3 0zm.7 18.4H4V1.7h12v16.7z"/>
                <path d="M5.4 3.7v5.7h4V3.7h-4zm3.3 5H6.1V4.4h2.7v4.3zM10.8 3.7h3.7v.7h-3.7zM10.8 5.4h3.7v.7h-3.7zM10.8 7h3.7v.7h-3.7zM10.8 8.7h3.7v.7h-3.7zM5.4 11.1v5.7h4v-5.7h-4zm3.3 5H6.1v-4.4h2.7v4.4zM10.8 11.1h3.7v.7h-3.7zM10.8 12.7h3.7v.7h-3.7zM10.8 14.4h3.7v.7h-3.7zM10.8 16.1h3.7v.7h-3.7z"/>
            </svg>
        </div>

        <div>
            <svg id="guides" class="c-icons__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M14.5 0H4.4c-.9 0-.8.9-.8 1.9v13.2c0 1.1-.1 1.7.8 1.9h10.1c1.1 0 1.9-.9 1.9-1.9V1.9c0-1-.8-1.9-1.9-1.9zm.6 15.7H4.9V1.4h10.3v14.3z"/>
                <path d="M6.1 2.9v2.6h5.1V2.9H6.1zm4.5 2h-4V3.4h4v1.5zM12.9.6h.6v15.1h-.6zM9.5 20h-.1l-1.9-.8-1.9.8h-.3c-.1-.1-.1-.1-.1-.2v-3.4c0-.2.1-.3.3-.3h4c.2 0 .3.1.3.3v3.4c0 .1 0 .2-.1.2h-.2zm-2-1.4h.1l1.6.7v-2.7H5.8v2.7l1.6-.7h.1z"/>
            </svg>
        </div>

        <div>
            <svg id="library" class="c-icons__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M5.5 19.9H3.1c-1 0-1.8-.8-1.8-1.8V1.8C1.3.8 2.1 0 3.1 0h2.4c1 0 1.8.8 1.8 1.8v16.4c-.1.9-.9 1.7-1.8 1.7zM3.1 1.3c-.2 0-.5.2-.5.5v16.4c0 .2.2.5.5.5h2.4c.2 0 .5-.2.5-.5V1.8c0-.2-.2-.5-.5-.5H3.1z"/>
                <path d="M2 3.6h4.6v.7H2zM2 15.7h4.6v.7H2zM10.8 19.9H8.2c-.9 0-1.6-.7-1.6-1.6V5.5c0-.9.7-1.6 1.6-1.6h2.6c.9 0 1.6.7 1.6 1.6v12.7c0 1-.7 1.7-1.6 1.7zM8.2 5.2c-.2 0-.3.2-.3.3v12.7c0 .2.2.3.3.3h2.6c.2 0 .3-.2.3-.3V5.5c0-.2-.2-.3-.3-.3H8.2z"/>
                <path d="M7.2 6.8h4.6v.7H7.2zM7.2 16.3h4.6v.7H7.2z"/>
                <path d="M14.3 20c-.8 0-1.5-.6-1.5-1.4L11.7 8c-.1-.9.5-1.6 1.4-1.7l2.7-.3c.9-.1 1.6.5 1.7 1.4L18.6 18c.1.9-.5 1.6-1.4 1.7l-2.7.3h-.2zM16 7.3l-2.8.3c-.1 0-.2.1-.2.3l1.1 10.6c0 .1.1.1.1.2 0 0 .1.1.2.1l2.7-.3c.1 0 .1-.1.2-.1 0 0 .1-.1.1-.2L16.2 7.6c0-.2-.1-.3-.2-.3z"/>
                <path d="M12.48 8.934l4.575-.476.073.696-4.575.476zM13.278 16.938l4.575-.477.073.696-4.575.477z"/>
            </svg>
        </div>

        <div>
            <svg id="download" class="c-icons__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M44.8 28.8c-.7-.8-2-.9-2.8-.2l-10 8.9V2c0-1.1-.9-2-2-2s-2 .9-2 2v35.5l-10-8.9c-.8-.7-2.1-.7-2.8.2-.7.8-.7 2.1.2 2.8l13.3 11.8c.4.3.9.5 1.3.5s.9-.2 1.3-.5l13.3-11.8c.8-.7.9-1.9.2-2.8zM56 45v6.1c0 2.7-2.2 4.9-4.9 4.9H8.9C6.2 56 4 53.8 4 51.1V45H0v6.1C0 56 4 60 8.9 60h42.2c4.9 0 8.9-4 8.9-8.9V45h-4z"/></svg>
        </div>

        <div>
            <svg id="print" class="c-icons__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M60 47.2H0V30.3c0-3.7 3.2-6.7 7.2-6.7h45.6c4 0 7.2 3 7.2 6.7v16.9zM4.2 43.3h51.6v-13c0-1.5-1.3-2.8-3-2.8H7.2c-1.6 0-3 1.2-3 2.8v13z"/><path d="M41.2 60H18.8c-2.6 0-4.6-1.9-4.6-4.3V43.3h31.6v12.4c0 2.4-2.1 4.3-4.6 4.3zM18.4 47.2v8.5c0 .2.2.4.4.4h22.3c.2 0 .4-.2.4-.4v-8.5H18.4z"/><path d="M42.1 45.7H17.9v-7.9h24.2v7.9zm-23.2-.9H41v-5.9H18.9v5.9zM45.5 11.8H32.9V0H34c6.4 0 11.6 4.9 11.6 10.8v1zM35 9.8h8.4c-.5-4.1-4-7.4-8.4-7.8v7.8zM43.4 11.8h2.1v8.9h-2.1z"/><path d="M16.6 20.7V2h16.3V0H14.5v20.7z"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M2.945 9.074C3.51 4.513 6.964 1 11.136 1c4.174 0 7.631 3.519 8.192 8.085.939.117 1.672 1.044 1.672 2.17 0 1.204-.84 2.182-1.874 2.182-.054 0-.108-.003-.161-.008-1.113 3.695-4.2 6.355-7.829 6.355-3.645 0-6.743-2.683-7.844-6.402a1.608 1.608 0 0 1-.418.055C1.84 13.437 1 12.459 1 11.255c0-1.205.84-2.183 1.874-2.183l.071.002z" fill="none" stroke="#000" strokeWidth="2"/><circle cx="7.99" cy="10.109" r=".903"/><circle cx="14.01" cy="10.109" r=".903"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 10" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M1 1v7.831h7.831M2.059 7.45S10.556-1.499 21 7.225" fill="none" stroke="#000" strokeWidth="2"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M15.993 5.163H1.917L.75 20.707h16.41L15.993 5.163z" fill="none" stroke="#000" strokeWidth="1.5"/><path d="M5.571 8.77S5.383.707 8.955.707h.116c3.572 0 3.384 8.063 3.384 8.063" fill="none" stroke="#000"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-miterlimit="1.5"><path d="M8.484 1.007h4.028M.451 1.007h4.361M16.089 1.007h4.362" fill="none" stroke="#000" strokeWidth=".5"/><path fill-rule="nonzero" d="M5.594 0h2.158v5.036H5.594zM13.16 0h2.158v5.036H13.16z"/><path d="M16.107 1.87c.885.013.981.03 1.866.051a2.667 2.667 0 0 1 2.436 2.435c.093 4.019.002 8.04.002 12.059-.03 1.323-1.102 2.452-2.438 2.554-4.971.115-9.944.002-14.916.002-1.321-.03-2.452-1.1-2.553-2.435C.387 12.516.5 8.493.5 4.471c.038-1.327 1.106-2.448 2.439-2.55.885-.021 1.06-.038 1.945-.051v2.198c-.837.005-.965.008-1.802.008a.429.429 0 0 0-.423.391c-.115 3.991-.301 7.998.002 11.98.025.2.185.35.385.366 4.945.114 9.906.375 14.838-.002a.424.424 0 0 0 .368-.385c.093-3.994.303-8.001-.001-11.984a.417.417 0 0 0-.385-.365c-.849-.02-.91-.033-1.759-.04V1.87zM12.51 4.026c-1.802-.001-2.226.013-4.029.027V1.845a180.37 180.37 0 0 1 4.029 0v2.181z" fill-rule="nonzero"/><path fill="none" stroke="#000" strokeWidth=".5" stroke-linecap="square" d="M4.302 6.772H16.13v7.724H4.302zM4.302 10.634h11.827M8.345 6.772v7.724M12.078 6.772v7.724"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M3.326 11.124v-.096c0-4.46 3.522-8.554 7.802-8.081 1.897.209 2.997.275 4.664-1.929.235-.31-.088 3.426.41 3.794 1.988 1.465 2.728 3.499 2.728 6.216v.028c.098-.018.199-.028.303-.028.975 0 1.767.842 1.767 1.878 0 1.037-.792 1.878-1.767 1.878a1.69 1.69 0 0 1-1.028-.35c-1.242 2.76-3.945 4.676-7.077 4.676-3.193 0-5.94-1.991-7.148-4.838a1.708 1.708 0 0 1-1.213.512c-.975 0-1.767-.841-1.767-1.878 0-1.036.792-1.878 1.767-1.878.196 0 .383.034.559.096z" fill="none" stroke="#000" strokeWidth="2"/><circle cx="8.161" cy="11.823" r="1.136"/><circle cx="13.839" cy="11.823" r="1.136"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M10 17.345l3.692 3.772L22 10.883" fill="none" stroke="#000" strokeWidth="2"/><circle cx="16" cy="16" r="15" fill="none" stroke="#000" strokeWidth="2" stroke-linecap="square"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M1 11l7-5-7-5" fill="none" stroke="#000" strokeWidth="2"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M.75 9.886L10.397.75 20.75 9.886" fill="none" stroke="#000" strokeWidth="1.5"/><path d="M17.353 7.013v10.583c0 .729-.592 1.321-1.321 1.321H5.468a1.322 1.322 0 0 1-1.321-1.321V7.031" fill="none" stroke="#000" strokeWidth="1.5" stroke-linecap="butt"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><g fill="none" stroke="#000" strokeWidth="1.5"><path d="M.75 8.566h10.998M10.183 5.889l2.677 2.677-2.677 2.677"/></g><path d="M6.039 4.709A7.799 7.799 0 0 1 12.848.75a7.906 7.906 0 0 1 7.902 7.902 7.905 7.905 0 0 1-7.902 7.901 7.799 7.799 0 0 1-6.809-3.959" fill="none" stroke="#000" strokeWidth="1.5"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><g fill="none" stroke="#000" strokeWidth="1.5"><path d="M8.64 8.738h10.999M18.073 11.415l2.677-2.677-2.677-2.677"/></g><path d="M15.461 12.594a7.799 7.799 0 0 1-6.809 3.959A7.905 7.905 0 0 1 .75 8.652 7.906 7.906 0 0 1 8.652.75a7.799 7.799 0 0 1 6.809 3.959" fill="none" stroke="#000" strokeWidth="1.5"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><circle cx="11" cy="11" r="10" fill="none" stroke="#000" strokeWidth="2"/><circle cx="11" cy="11" r="7.492" fill="none" stroke="#000"/><path d="M13.26 13.875c.12 0 .213.03.28.09.067.06.1.143.1.25 0 .113-.033.2-.1.26a.404.404 0 0 1-.28.09H8.74a.404.404 0 0 1-.28-.09c-.067-.06-.1-.147-.1-.26 0-.107.033-.19.1-.25s.16-.09.28-.09h.68v-2.55h-.76a.303.303 0 0 1-.215-.08.273.273 0 0 1-.085-.21c0-.087.028-.157.085-.21a.303.303 0 0 1 .215-.08h.76v-1.39c0-.587.172-1.053.515-1.4s.818-.52 1.425-.52c.4 0 .783.073 1.15.22.367.147.69.35.97.61.107.1.16.217.16.35a.396.396 0 0 1-.08.245c-.053.07-.117.105-.19.105a.522.522 0 0 1-.28-.11c-.327-.267-.622-.455-.885-.565a2.117 2.117 0 0 0-.825-.165c-.347 0-.623.105-.83.315-.207.21-.31.535-.31.975v1.33h1.58c.087 0 .158.027.215.08a.273.273 0 0 1 .085.21.273.273 0 0 1-.085.21.303.303 0 0 1-.215.08h-1.58v2.55h3.02z" fill-rule="nonzero"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M8.071 11.664a.614.614 0 0 0 0-.869l-.869-.869a.613.613 0 0 0-.868 0L.93 15.33a.614.614 0 0 0 0 .869l.869.869c.24.239.629.239.869 0l5.403-5.404z" fill="none" stroke="#000" strokeWidth="1.5"/><path d="M15.914 2.084a4.56 4.56 0 0 1 0 6.444 4.56 4.56 0 0 1-6.445 0 4.56 4.56 0 0 1 0-6.444 4.559 4.559 0 0 1 6.445 0z" fill="#ececec" stroke="#000" strokeWidth="1.5"/><path d="M12.496 8.015a2.907 2.907 0 0 0 2.905-2.905" fill="none" stroke="#000" strokeWidth=".8"/><path d="M9.469 8.528L8.151 9.846" fill="none" stroke="#000" strokeWidth="1.5"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 12" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M1 1l10 9.087L21 1" fill="none" stroke="#000" strokeWidth="2"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M1 11.769l6.154 6.288L21 1" fill="none" stroke="#000" strokeWidth="2"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><circle cx="11" cy="11" r="10" fill="none" stroke="#000" strokeWidth="2"/><circle cx="11" cy="11" r="7.492" fill="none" stroke="#000"/><path d="M11 11.327l4.018-3.474M11 11.327L8.272 9.268" fill="none" stroke="#000"/><circle cx="11" cy="11.327" r="1"/></svg>
        </div>

        <div>
            <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="1.5"><path fill="none" stroke="#000" strokeWidth=".5" d="M5.662 14.939h4.07v3.479h-4.07z"/><path fill-rule="nonzero" d="M0 17.669h15.394v1.5H0z"/><g fill-rule="nonzero"><path d="M1.947 0h1.5v18.779h-1.5zM11.947 0h1.5v18.779h-1.5z"/></g><path fill-rule="nonzero" d="M.918 0h13.557v1H.918zM3.168 1.014h9.057v.5H3.168zM.918 2.25h13.557v1H.918z"/><path d="M4.438 4.43h1.5v1.5h-1.5zM6.938 4.43h1.5v1.5h-1.5zM9.438 4.43h1.5v1.5h-1.5zM4.438 6.763h1.5v1.5h-1.5zM6.938 6.763h1.5v1.5h-1.5zM9.438 6.763h1.5v1.5h-1.5z"/><g><path d="M4.438 9.097h1.5v1.5h-1.5zM6.938 9.097h1.5v1.5h-1.5zM9.438 9.097h1.5v1.5h-1.5z"/></g><g><path d="M4.438 11.43h1.5v1.5h-1.5zM6.938 11.43h1.5v1.5h-1.5zM9.438 11.43h1.5v1.5h-1.5z"/></g></svg>
        </div>

        <div>
            <svg id="print" aria-labelledby="print" role="img" viewBox="0 0 120 120" width="120" height="120"><title>print results</title><path class="st0" d="M101.1 35.2h-2.3V0H21.2v35.2h-2.3c-10 0-18 8.1-18 18v34.9c0 10 8.1 18 18 18h2.3V120h77.6v-13.8h2.3c10 0 18-8.1 18-18v-35c0-9.9-8.1-18-18-18zm-72.7-28h63.2v27.1H28.4V7.2zm-9.5 35.2H101c6 0 10.8 4.9 10.8 10.8V55H8.1v-1.8c0-5.9 4.9-10.8 10.8-10.8zm72.7 70.4H28.4v-37h63.2v37zm9.5-13.9h-2.3V68.6H21.2V99h-2.3c-6 0-10.8-4.9-10.8-10.8V62.3h103.8v25.9c0 5.9-4.9 10.7-10.8 10.7z"></path><path class="st0" d="M84.5 83.6h-49c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h49c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6zM63.8 97.1H35.5c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h28.3c2 0 3.6-1.6 3.6-3.6 0-1.9-1.6-3.6-3.6-3.6z"></path><circle class="st0" cx="94.9" cy="48.8" r="3.9"></circle></svg>
        </div>

        <div>
            <svg id="summary" viewBox="0 0 120 120"><path d="M48.4 120h61.2V0H10.4v81.5m36 24.9l-22.7-23h22.7v23zm55.3 5.7H54.3V75.6h-36V7.9h83.5v104.2z"/><path d="M84.5 31.1h-49c-2.2 0-4-1.8-4-4s1.8-4 4-4h49.1c2.2 0 4 1.8 4 4s-1.9 4-4.1 4zM84.5 47.9h-49c-2.2 0-4-1.8-4-4s1.8-4 4-4h49.1c2.2 0 4 1.8 4 4s-1.9 4-4.1 4z"/></svg>
        </div>

        <div>
            <svg id="formula" viewBox="0 0 120 120"><path d="M30.4 120L16.7 69.4H5c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3h18.2l7.7 28.4L58.4 0H115c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3H64.8L30.4 120z"/><path d="M88.5 51.4l9.4-11.9c1.5-1.9 1.1-4.5-.7-6-1.9-1.5-4.5-1.1-6 .7l-8.1 10.3L75 34.2c-1.5-1.9-4.1-2.2-6-.7-1.9 1.5-2.2 4.1-.7 6l9.4 11.9-9.4 11.9c-1.5 1.9-1.1 4.5.7 6 .8.6 1.7.9 2.6.9 1.3 0 2.5-.6 3.4-1.6l8.1-10.3 8.1 10.3c.8 1.1 2.1 1.6 3.4 1.6.9 0 1.8-.3 2.6-.9 1.9-1.5 2.2-4.1.7-6l-9.4-11.9z"/></svg>
        </div>

        <div>
            <svg id="info" viewBox="0 0 120 120"><path d="M60 0C26.8 0 0 26.8 0 60s26.8 60 60 60 60-26.8 60-60S93.2 0 60 0zm0 109.4c-26.8 0-49.4-21.9-49.4-49.4 0-26.8 21.9-49.4 49.4-49.4s49.4 21.9 49.4 49.4c0 26.8-22.6 49.4-49.4 49.4z"/><path d="M60 83.3c-4.2 0-7.1 3.5-7.1 7.1 0 4.2 3.5 7.1 7.1 7.1s7.1-3.5 7.1-7.1-2.9-7.1-7.1-7.1zM60 21.9c-11.3 0-19.8 9.2-19.8 19.8 0 2.8 2.1 5.6 5.6 5.6 2.8 0 5.6-2.1 5.6-5.6 0-4.9 4.2-9.2 9.2-9.2s9.2 4.2 9.2 9.2-4.2 9.2-9.2 9.2c-3.5 0-6.4 2.8-6.4 5.6v10.6c0 2.8 2.1 5.6 5.6 5.6s5.6-2.1 5.6-5.6v-5.6c8.5-2.1 14.8-9.9 14.8-19.1-.4-11.3-8.9-20.5-20.2-20.5z"/></svg>
        </div>

        <div>
            <svg id="save" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M97.7 0H1.3v120h117.5V21L97.7 0zM86.9 4.9v34.3H28.2V4.9h58.7zM23.3 115.1V68.6h71v46.5h-71zm90.6 0H99.2V63.7H18.4v51.4H6.1V4.9h17.1v39.2h68.6V4.9h3.9l18.2 18.2v92z"></path><path class="st0" d="M33.1 80.8h17.1c1.4 0 2.4-1.1 2.4-2.4S51.5 76 50.2 76H33.1c-1.4 0-2.4 1.1-2.4 2.4s1 2.4 2.4 2.4zM57.6 85.7H33.1c-1.4 0-2.4 1.1-2.4 2.4 0 1.4 1.1 2.4 2.4 2.4h24.5c1.4 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4zM63.2 86.4c-.4.5-.7 1.1-.7 1.7 0 .6.3 1.3.7 1.7.5.4 1.1.7 1.7.7.6 0 1.3-.3 1.7-.7.4-.5.7-1.1.7-1.7 0-.6-.3-1.3-.7-1.7-.9-.9-2.5-.9-3.4 0zM82 9.8H67.3v24.5H82V9.8zm-4.9 19.6h-4.9V14.7h4.9v14.7z"></path></svg>
        </div>

        <div>
            <svg id="refresh" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M118.4 27.7c-1.3-.5-2.7.2-3.2 1.5L111 40.4c-4.5-13.1-13.7-23.8-26.1-30-12.5-6.3-26.8-7.4-40.1-3C22.9 14.6 8.1 35.8 8.7 58.9c0 1.4 1.2 2.4 2.5 2.4h.1c1.4 0 2.5-1.2 2.4-2.6-.6-20.9 12.8-40 32.6-46.5 12.1-4 24.9-3 36.3 2.7 11.3 5.7 19.8 15.5 23.7 27.6.1.2.1.3.2.5l-11.8-4.3c-1.3-.5-2.7.2-3.2 1.5s.2 2.7 1.5 3.2l17.2 6.3c.3.1.6.2.9.2.4 0 .7-.1 1.1-.2.6-.3 1.1-.8 1.3-1.4l6.3-17.2c.5-1.5-.1-2.9-1.4-3.4zM109.6 44h.1-.1zM108.7 58.7c-1.4 0-2.5 1.2-2.4 2.6.6 20.9-12.8 40-32.6 46.5-24.9 8.2-51.8-5.4-60-30.3-.1-.2-.1-.3-.2-.5l11.8 4.3c1.3.5 2.7-.2 3.2-1.5s-.2-2.7-1.5-3.2L9.7 70.4c-1.3-.5-2.7.2-3.2 1.5L.2 89.1c-.5 1.3.2 2.7 1.5 3.2.3.1.6.2.9.2 1 0 2-.6 2.4-1.6l4-11.3c7.4 21.8 27.9 35.7 49.8 35.7 5.4 0 11-.9 16.4-2.6 21.9-7.2 36.8-28.4 36.1-51.4-.1-1.5-1.2-2.6-2.6-2.6zM10.3 76c.1 0 0 0 0 0z"></path></svg>
        </div>

        <div>
            <svg id="warning" viewBox="0 0 120 120"><path d="M118.5 95.2L69.2 13.6c-1.9-3.2-5.5-5.2-9.2-5.2s-7.3 2-9.2 5.2L1.5 95.2c-2 3.3-2.1 7.5-.2 10.9 1.9 3.4 5.5 5.5 9.4 5.5h98.4c3.9 0 7.5-2.1 9.4-5.5 2-3.4 2-7.5 0-10.9zm-6.7 7.1c-.5.9-1.5 1.5-2.6 1.5H10.8c-1.1 0-2.1-.6-2.6-1.5s-.5-2.1 0-3l49.2-81.6c.5-.9 1.5-1.4 2.5-1.4s2 .6 2.5 1.4l49.2 81.6c.7.9.7 2 .2 3z"/><path d="M60 40.5c-3 0-5.3 1.6-5.3 4.4 0 8.6 1 21 1 29.6 0 2.2 2 3.2 4.3 3.2 1.7 0 4.2-.9 4.2-3.2 0-8.6 1-21 1-29.6.1-2.8-2.3-4.4-5.2-4.4zM60 82.8c-3.2 0-5.6 2.5-5.6 5.6 0 3 2.4 5.6 5.6 5.6 3 0 5.5-2.6 5.5-5.6 0-3.1-2.5-5.6-5.5-5.6z"/></svg>
        </div>

        <div>
            <svg id="claim-dashboard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M14.7 18.1H10zM10 1.9c-5.5 0-10 4.2-10 9.4 0 2.7 1.2 5.1 3.1 6.8H10 5.3 16.9c1.9-1.7 3.1-4.1 3.1-6.8 0-5.2-4.5-9.4-10-9.4zm6.7 14.6H3.3c-1.3-1.4-2-3.2-2-5.2 0-4.4 3.9-8.1 8.7-8.1 4.8 0 8.7 3.6 8.7 8.1 0 2-.8 3.8-2 5.2z"/>
                <path d="M6.5 14.5h7.1v1H6.5zM1 10.6h2.3v1.9H1zM16.8 10.6h2.3v1.9h-2.3zM9 2.6h1.9v2.6H9zM3.233 5.85l1.418-1.265 1.464 1.643-1.418 1.264zM15.3 7.456l-1.418-1.264 1.464-1.642 1.418 1.264z"/>
                <g>
                    <path d="M10 13.4c-.3 0-.6-.1-.9-.3-.4-.3-.7-.6-.8-.9-.1-.3.1-.7.3-1 .2-.1.3-.2.4-.4 2-2.6 2.8-3.4 3.4-3 .6.3.3 1.5-1.2 4.4-.1.2-.2.3-.2.4-.2.4-.4.7-.8.8H10zm2.1-5c-.3.1-1 .8-2.5 2.8-.1.2-.2.3-.3.4-.2.2-.3.4-.3.5 0 .2.3.4.5.5.2.1.5.2.6.2.1 0 .2-.2.4-.4 0-.1.1-.3.2-.5 1.1-2.3 1.4-3.2 1.4-3.5z"/>
                </g>
            </svg>
        </div>
            
        <div>
            <svg id="claim" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M2.6 6.9C1.7 6.9 1 6.1 1 5.2c0-.9.7-1.7 1.6-1.7.9 0 1.6.7 1.6 1.7.1.9-.7 1.7-1.6 1.7zm0-2.7c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zM19 4.3H6V6h13V4.3zM2.6 11.7C1.7 11.7 1 11 1 10c0-.9.7-1.7 1.6-1.7.9 0 1.6.7 1.6 1.7.1 1-.7 1.7-1.6 1.7zm0-2.7c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zM19 9.2H6v1.7h13V9.2zM2.6 16.5c-.9 0-1.6-.7-1.6-1.7 0-.9.7-1.7 1.6-1.7.9 0 1.6.7 1.6 1.7.1 1-.7 1.7-1.6 1.7zm0-2.7c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zM19 14H6v1.7h13V14z"/>
            </svg>
        </div>

        <div>
            <svg id="edit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M.2 19.6c-.2-.1-.2-.3-.2-.5l.8-5.3c0-.1.1-.3.2-.4L13.3 1.3c1.5-1.5 4-1.5 5.5.1s1.5 4-.1 5.5L6.4 19c-.1.1-.2.2-.4.2l-5.3.6c-.2.1-.4 0-.5-.2zM2 14.2l-.6 4.2 4.3-.5L17.9 6c1-1 1-2.7 0-3.7s-2.7-1-3.7 0L2 14.2z"/>
                <path d="M1.397 13.865l.5-.49 4.547 4.645-.5.49zM13.581 1.932l.5-.49 4.547 4.646-.5.49zM12.59 2.882l.5-.49 4.546 4.646-.5.49z"/>
            </svg>
        </div>

        <div>
            <svg id="information-dashboard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M.8 19.7c-.4 0-.7-.3-.7-.7V.7C.2.3.5 0 .8 0s.7.3.7.7V19c0 .4-.3.7-.7.7z"/>
                <path d="M19.2 20H.8c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h18.4c.4 0 .7.3.7.7-.1.4-.4.7-.7.7zM3.1 17.4h-.2c-.3-.1-.6-.5-.5-.8.4-1.5 2.6-9.1 6-8.3 1.1.2 1.5 1.3 1.9 2.2.4 1 .7 1.6 1.3 1.7.8.1 3.7-3.6 6.2-7.9.2-.3.6-.4.9-.2.3.2.4.6.2.9-1.5 2.7-5.4 8.9-7.6 8.5C9.9 13.3 9.4 12 9 11c-.3-.8-.6-1.4-1-1.4-1.6-.4-3.6 4.2-4.5 7.4.2.2-.1.4-.4.4z"/>
            </svg>
        </div>

        <div>
            <svg id="book-audiometry" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M16.8 19.8h-10c-.2 0-.4-.1-.5-.3-.1-.2-.1-.4-.1-.6.8-2 1.8-5.4 1.4-6.4-.2-.4-3-6.9-.5-10.3C8.3.6 10.4 0 13.4.4c2.4.3 4.1 1.6 5 3.7 1.4 3.3.1 7.7-.5 8.6-.8 1-1.8 1.6-2.9 1.6-.4 0-.7 0-1.1-.1.4 1.6 2.3 3.4 3 4.1.5.4.7.7.5 1.1-.1.2-.4.4-.6.4zm-9-1.3h7.4c-1.2-1.1-2.7-2.9-2.8-4.8-.8-.4-1.3-.9-1.3-1-.3-.3-.3-.6 0-.9.3-.3.6-.3.9 0 0 0 .5.5 1.3.9.5.2.9.4 1.5.3.7 0 1.4-.4 2-1.2.5-.6 1.6-4.5.4-7.3-.6-1.7-2-2.7-3.9-2.9-2.5-.3-4.2.1-5.1 1.3-2.1 2.8.6 9 .7 9 .5 1.6-.5 4.8-1.1 6.6zM1.3 12.5c-.1 0-.2 0-.2-.1-.1-.1-.1-.3 0-.4.5-.5.7-1 .7-1.5 0-.8-.7-1.5-.7-1.5-.1-.1-.1-.3 0-.4.1-.1.3-.2.5 0 0 0 .9.8.9 1.9 0 .7-.3 1.4-.9 2h-.3z"/>
                <path d="M2.7 14.7c-.1 0-.2 0-.3-.1-.1-.1-.1-.3.1-.5 1.3-1 1.9-2.2 1.9-3.4 0-2-1.9-3.6-1.9-3.6-.2-.1-.2-.3-.1-.5.1-.1.3-.2.5 0C3 6.7 5 8.4 5 10.7c0 1.4-.7 2.7-2.1 3.9-.1 0-.2.1-.2.1z"/>
            </svg>
        </div>

        <div>
            <svg id="tools" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M3 9.5l-3-3 1.3-1.3h.1c.4 0 .9-.1 1.1-.2.2-.2.2-.7.2-1.1v-.1l.1-.1c.1-.1 1.4-1.7 2.4-2.4 2.2-1.4 4.9.9 5 1l.6.5-.8.1s-2.6.3-2.7 1.6c.1.3 1 1.2 1.7 1.9l.2.2-2 2.2-.3-.3c-.7-.9-1.6-1.7-1.8-1.7-.4 0-1 .8-1 1.3 0 .9-.8 1.3-.8 1.3l-.3.1zm-2.1-3l2.2 2.2c.1-.1.3-.3.3-.6 0-.8.7-2 1.6-2 .6 0 1.6 1 2.2 1.6l1.2-1.2c-1.7-1.7-1.7-2-1.7-2.1 0-1.3 1.5-1.9 2.5-2.2-.8-.4-2.4-1.2-3.6-.4-.8.6-1.9 1.8-2.2 2.2 0 .3-.1 1-.4 1.4-.3.3-1.1.4-1.4.4l-.7.7zm6.5-2.1z"/>
                <path d="M8.8 7.4s.1 0 .3.2l9.5 8.2c.1.1 0 .7-.5 1.2l-.3.3c-.4.4-.8.6-1.1.6h-.1L8.2 8.6l-.3-.3s0-.1.3-.4l.3-.3c.1-.2.3-.2.3-.2m0-1.3c-.4 0-.8.2-1.3.7l-.3.2c-1 1-.7 1.8 0 2.5l8.6 9.3c.3.3.6.4 1 .4.7 0 1.4-.3 2-1l.3-.3c1-1 1.2-2.4.5-3L10 6.7c-.4-.4-.8-.6-1.2-.6z"/>
            </svg>
        </div>

        <div>
            <svg id="resources" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M15.3 0H4.7C3.5 0 2.5 1 2.5 2.3v15.5c0 1.2 1 2.3 2.3 2.3h10.5c1.2 0 2.3-1 2.3-2.3V2.3C17.5 1 16.5 0 15.3 0zm.7 18.4H4V1.7h12v16.7z"/>
                <path d="M10.1 3.3V9h4V3.3h-4zm3.3 5.1h-2.7V4h2.7v4.4zM5.7 3.3h3.7V4H5.7zM5.7 5h3.7v.7H5.7zM5.7 6.7h3.7v.7H5.7zM5.7 8.4h3.7v.7H5.7zM5.7 10h8.4v.7H5.7zM5.7 11.7h7.7v.7H5.7zM5.7 13.4h8.4v.7H5.7zM5.7 15.1h8.4v.7H5.7z"/>
            </svg>
        </div>

        <div>
            <svg id="support" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10.5 19.1c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2-.6 1.2-1.2 1.2zm0-1.8c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6z"/>
                <path d="M11.4 18.2c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h1.4c1.3 0 2.3 0 2.9-.6.4-.4.6-1.1.5-2.2 0-.2.1-.3.3-.3.2 0 .3.1.3.3 0 1.3-.2 2.1-.7 2.6-.7.8-1.9.8-3.3.8h-1.4z"/>
                <path d="M17.5 8.4v-.9c0-1.8-.5-3.6-1.9-4.8C14.3 1.5 12.1.9 10 .9s-4.3.6-5.6 1.8C3 3.9 2.5 5.7 2.5 7.5v.9C1.7 8.9 1 9.9 1 11.5 1 14 2.6 15 3.9 15c0 .7.5 1.2 1.2 1.2s1.2-.6 1.2-1.2V8.2c0-.7-.6-1.2-1.2-1.2-.5 0-1 .4-1.1.9 0-1.5.3-3.3 1.2-4.2 1.5-1.3 3.1-1.5 4.7-1.5h.2c1.6 0 3.2.2 4.7 1.5.9.9 1.2 2.7 1.2 4.2-.1-.5-.6-.9-1.1-.9-.6 0-1.2.5-1.2 1.2V15c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c1.3 0 2.9-1 2.9-3.5 0-1.6-.7-2.6-1.5-3.1zM3.9 13.7c-.8 0-1.9-.7-1.9-2.3 0-1.6 1.1-2.3 1.9-2.3v4.6zm.4-5.5c0-.5.4-.8.8-.8.5 0 .8.4.8.8v6.5c0 .5-.4.8-.8.8-.5 0-.8-.4-.8-.8V8.2zm11.4 6.5c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8V8.2c0-.4.3-.8.8-.8.4 0 .8.3.8.8v6.5zm.4-1V9.1c.8 0 1.9.7 1.9 2.3 0 1.6-1.1 2.3-1.9 2.3z"/>
            </svg>
        </div>

        <div>
            <svg id="logout" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10.5 8.4V6.7h5.1l-1.9-2.1 1.5-1.3L19 7.5l-3.9 4.2-1.5-1.3 1.9-2.1h-5z"/>
                <path d="M7.6 14.1V6.3l-5-3.8-.2 11.2.6.4h4.6zm0 .6H3.9l3.6 2.6.1-2.6zm4.5-10.6h-.6V.8H3.1L9 5.4v8.7h2.4v-3.2h.6v3.9H9v4.4c0 .2-.2.5-.5.6-.3.1-.8 0-.9-.1 0-.2-6.6-5-6.6-5V.3h11.1v3.8z"/>
            </svg>
        </div>

        <div>
            <svg id="dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10 14.3L5 10 0 5.7h20l-10 8.6zm-4.4-5l4.4 3.8 7.7-6.6H2.4l3.2 2.8z"/>
            </svg>
        </div>

        <div>
            <svg id="reset" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M18.6 9.9c-.4 0-.8.3-.8.8 0 4.3-3.5 7.8-7.8 7.8S2.2 15 2.2 10.7 5.7 2.9 10 2.9c1.7 0 3.3.6 4.6 1.5l-2.7.1c-.4 0-.7.4-.7.8s.4.7.8.7l4.6-.2c.2 0 .4-.1.5-.2.1-.2.2-.3.2-.6L17.1.7c0-.4-.4-.7-.8-.7s-.7.4-.7.8l.1 2.5c-1.6-1.3-3.6-2-5.7-2C4.8 1.3.6 5.5.6 10.6c0 5.1 4.2 9.3 9.4 9.3s9.4-4.2 9.4-9.3c0-.4-.4-.7-.8-.7z"/>
            </svg>
        </div>

        <div>
            <svg id="add" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M116.9 56.9H63.1V3.1C63.1 1.4 61.7 0 60 0s-3.1 1.4-3.1 3.1v53.8H3.1C1.4 56.9 0 58.3 0 60c0 1.7 1.4 3.1 3.1 3.1h53.8v53.8c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1V63.1h53.8c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1z"></path></svg>
        </div>

        <div>
            <svg id="apply" viewBox="0 0 145.3 119.4"><path d="M4 119.6c-.5 0-1-.1-1.6-.3-1.8-.7-2.8-2.7-2.4-4.5 15-69.5 66.5-82.5 83.7-84.9v-26c0-1.6 1-3.1 2.5-3.7 1.5-.6 3.2-.3 4.4.8l53.5 52.6c.8.8 1.2 1.8 1.2 2.9 0 1.1-.4 2.1-1.2 2.9L90.6 112c-1.1 1.1-2.9 1.5-4.4.8-1.5-.6-2.5-2.1-2.5-3.7V82.5h-.2c-14.8 0-55 3.1-76.3 35.3-.6 1.2-1.9 1.8-3.2 1.8zm79.7-45.1c1.8 0 3.3 0 4.3.1 2.1.1 3.8 1.9 3.8 4v20.9l43.8-43.1-43.8-43v20c0 2.1-1.6 3.8-3.7 4-9.8.8-55.8 7.7-75.3 61.7C37 77 70.3 74.5 83.7 74.5z"/></svg>
        </div>
        
        <div>
            <svg id="delete" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M109.1 11.3H76.9V5.6c0-3.1-2.5-5.6-5.6-5.6H48.8c-3.1 0-5.6 2.5-5.6 5.6v5.7H10.9c-1.6 0-2.8 1.3-2.8 2.8 0 1.5 1.3 2.8 2.8 2.8h5.2l3.6 92.9c.2 5.7 4.9 10.1 10.5 10.1h59.5c5.7 0 10.3-4.5 10.5-10.1l3.6-92.9h5.2c1.6 0 2.8-1.3 2.8-2.8.1-1.5-1.2-2.8-2.7-2.8zM48.8 5.6h22.4v5.6H48.8V5.6zm45.9 104c-.1 2.6-2.3 4.7-4.9 4.7H30.3c-2.6 0-4.8-2.1-4.9-4.7l-3.6-92.7h76.4l-3.5 92.7z"></path><path class="st0" d="M42.8 42.6c0-1.5-1.3-2.8-2.8-2.8-1.6 0-2.8 1.3-2.8 2.9l.9 46.1c0 1.5 1.3 2.8 2.8 2.8 1.6 0 2.8-1.3 2.8-2.9l-.9-46.1zM60 39.8c-1.6 0-2.8 1.3-2.8 2.8v46.1c0 1.6 1.3 2.8 2.8 2.8 1.6 0 2.8-1.3 2.8-2.8V42.6c0-1.6-1.2-2.8-2.8-2.8zM80.1 39.8c-1.5 0-2.9 1.2-2.9 2.8l-.9 46.1c0 1.6 1.2 2.8 2.8 2.9 1.5 0 2.8-1.2 2.8-2.8l.9-46.1c.1-1.6-1.1-2.9-2.7-2.9z"></path></svg>
        </div>

        <div>
            <svg id="edit" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M16.3 120l-5.4-31.5 59-83.1c2-2.8 4.9-4.6 8.3-5.2 3.4-.6 6.7.2 9.5 2.2l16.2 11.5c2.8 2 4.6 4.9 5.2 8.3.6 3.4-.2 6.7-2.2 9.5l-59 83.1-31.6 5.2zm1.6-29.9l3.8 22.3 22.3-3.8 57.4-80.9c.9-1.3 1.3-2.9 1-4.6-.3-1.6-1.2-3-2.5-4L83.8 7.7c-1.3-.9-2.9-1.3-4.6-1-1.6.3-3 1.2-4 2.5L17.9 90.1z"></path></svg>
        </div>

        <div>
            <svg id="toggle" viewBox="0 0 120 120"><path d="M102 52c-.8 0-1.5-.3-2.1-.9L60 11.3 20.2 51.1c-1.2 1.2-3.1 1.2-4.2 0-1.2-1.2-1.2-3.1 0-4.2L60 2.8l44.1 44.1c1.2 1.2 1.2 3.1 0 4.2-.6.6-1.4.9-2.1.9zM60 117.2L15.9 73.1c-1.2-1.2-1.2-3.1 0-4.2 1.2-1.2 3.1-1.2 4.2 0L60 108.7l39.8-39.8c1.2-1.2 3.1-1.2 4.2 0 1.2 1.2 1.2 3.1 0 4.2l-44 44.1z"/></svg>
        </div>
        
        <div>
            <svg id="filter" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M60 120c-9.4 0-16.5-4-16.5-9.2V75.3L0 31.6V20C0 7 30.9 0 60 0s60 7.1 60 20.2v11.6L76.5 75.3v35.5c0 5.2-7.1 9.2-16.5 9.2zM3.9 30l43.5 43.7v37.1c0 2.5 5.4 5.3 12.6 5.3s12.6-2.8 12.6-5.3V73.7l43.5-43.5v-10C116.1 12.5 93 3.9 60 3.9S3.9 12.4 3.9 20v10z"></path><path class="st0" d="M59.9 37.3c-13 0-25.3-1.6-34.6-4.4-4.5-1.3-8.3-2.9-10.9-4.5-1.8-1.1-2.8-2.1-3.3-2.6L10 24.7l.9-1.3c.6-.9 1.9-2 3.6-3 2.7-1.6 6.4-3.1 10.8-4.5 9.9-2.8 22.2-4.4 34.6-4.4 13 0 25.3 1.6 34.6 4.4 4.5 1.3 8.3 2.9 10.9 4.5 1.8 1.1 2.8 2.1 3.3 2.6l1.4 1.4-1.4 1.4c-.5.5-1.5 1.5-3.3 2.6-2.7 1.6-6.4 3.2-10.8 4.5-10 2.9-22.3 4.4-34.7 4.4zM15.4 24.4c.3.2.7.5 1.1.8 2.2 1.4 5.7 2.9 9.9 4.1 9 2.7 20.9 4.2 33.5 4.2 12.1 0 24-1.5 33.5-4.2 4.1-1.3 7.5-2.7 9.9-4.1.4-.2.7-.5 1-.7-.3-.2-.7-.5-1.1-.7-2.2-1.4-5.7-2.9-9.9-4.1-9-2.7-20.9-4.2-33.5-4.2-12.1 0-24 1.5-33.5 4.2-4.1 1.3-7.5 2.7-9.9 4.1-.4.2-.7.4-1 .6z"></path></svg>
        </div>

        <div>
            <svg id="load" viewBox="0 0 145.3 120"><path d="M143.8 37.1c-1.6-2.7-4.2-4.2-7.2-4.2h-13.1V13.2c0-7.3-5-13.2-11-13.2H11.1C5 0 .1 5.9.1 13.2v95.3c-.3 2.7.2 5.3 1.5 7.3 1.6 2.6 4.2 4.2 7.2 4.2h104c5.6 0 11.5-5.1 13.4-11.6l18.4-61.1c1.1-3.7.8-7.5-.8-10.2zM11.1 8.2h101.3c2.3 0 4.2 2.2 4.2 5v19.7H31.8c-5.6 0-11.5 5.1-13.4 11.7L6.9 84V13.2c0-2.8 1.9-5 4.2-5zm127.1 36.3l-18.4 61.1c-1 3.3-4.3 6.2-7 6.2H8.8c-.5 0-1.3-.1-1.6-.7-.4-.6-.4-1.6 0-2.8L25 47.2c.9-3.2 4.2-6.2 6.9-6.2h104.8c.5 0 1.3.1 1.6.7.2.6.2 1.7-.1 2.8z"/></svg>
        </div>
        
        <div>
            <svg id="locked" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M85.7 33.8h-6V17.4C79.7 7.8 71.9 0 62.3 0h-4.6c-9.6 0-17.4 7.8-17.4 17.4v16.3h-6c-9.6 0-17.4 7.8-17.4 17.4v51.4c0 9.6 7.8 17.4 17.4 17.4h51.4c9.6 0 17.4-7.8 17.4-17.4V51.2c0-9.6-7.8-17.4-17.4-17.4zM45.5 17.4c0-6.7 5.5-12.2 12.2-12.2h4.6c6.7 0 12.2 5.5 12.2 12.2v16.3h-29V17.4zm52.4 85.2c0 6.7-5.5 12.2-12.2 12.2H34.3c-6.7 0-12.2-5.5-12.2-12.2V51.2c0-6.7 5.5-12.2 12.2-12.2h51.4c6.7 0 12.2 5.5 12.2 12.2v51.4z"></path><path class="st0" d="M60 64.6c-6.8 0-12.3 5.5-12.3 12.3 0 6.8 5.5 12.3 12.3 12.3 6.8 0 12.3-5.5 12.3-12.3 0-6.8-5.5-12.3-12.3-12.3zM60 84c-3.9 0-7.1-3.2-7.1-7.1 0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1c0 3.9-3.2 7.1-7.1 7.1z"></path></svg>
        </div>
        
        <div>
            <svg id="minus" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M116.9 63.1H3.1C1.4 63.1 0 61.7 0 60c0-1.7 1.4-3.1 3.1-3.1h113.8c1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1z"></path></svg>
        </div>

        <div>
            <svg id="print" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M97.4 39.6H22.6V.3h74.8v39.3zm-69.8-4.9h64.9V5.3H27.6v29.4z"></path><path class="st0" d="M99.2 108.3h-6.7V73H27.6v35.3h-6.7C9.3 108.3 0 99 0 87.5v-32C0 44 9.3 34.7 20.8 34.7h78.4c11.5 0 20.8 9.3 20.8 20.8v32c0 11.5-9.3 20.8-20.8 20.8zm-1.8-4.9h1.8c8.8 0 15.9-7.1 15.9-15.9v-32c0-8.8-7.1-15.9-15.9-15.9H20.8c-8.8 0-15.9 7.1-15.9 15.9v32c0 8.8 7.1 15.9 15.9 15.9h1.8V68.1h74.8v35.3z"></path><path class="st0" d="M2.5 56h115.1v4.9H2.5zM97.4 119.7H22.6V68.1h74.8v51.6zm-69.8-5h64.9V73H27.6v41.7z"></path><path class="st0" d="M85 90.4H35c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h50c1.4 0 2.5 1.1 2.5 2.5s-1.2 2.5-2.5 2.5zM63.2 102.3H35c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h28.1c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.4 2.5z"></path><g><circle class="st0" cx="94" cy="47.9" r="3.8"></circle></g></svg>
        </div>
        
        <div>
            <svg id="refresh" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M118.4 27.7c-1.3-.5-2.7.2-3.2 1.5L111 40.4c-4.5-13.1-13.7-23.8-26.1-30-12.5-6.3-26.8-7.4-40.1-3C22.9 14.6 8.1 35.8 8.7 58.9c0 1.4 1.2 2.4 2.5 2.4h.1c1.4 0 2.5-1.2 2.4-2.6-.6-20.9 12.8-40 32.6-46.5 12.1-4 24.9-3 36.3 2.7 11.3 5.7 19.8 15.5 23.7 27.6.1.2.1.3.2.5l-11.8-4.3c-1.3-.5-2.7.2-3.2 1.5s.2 2.7 1.5 3.2l17.2 6.3c.3.1.6.2.9.2.4 0 .7-.1 1.1-.2.6-.3 1.1-.8 1.3-1.4l6.3-17.2c.5-1.5-.1-2.9-1.4-3.4zM109.6 44h.1-.1zM108.7 58.7c-1.4 0-2.5 1.2-2.4 2.6.6 20.9-12.8 40-32.6 46.5-24.9 8.2-51.8-5.4-60-30.3-.1-.2-.1-.3-.2-.5l11.8 4.3c1.3.5 2.7-.2 3.2-1.5s-.2-2.7-1.5-3.2L9.7 70.4c-1.3-.5-2.7.2-3.2 1.5L.2 89.1c-.5 1.3.2 2.7 1.5 3.2.3.1.6.2.9.2 1 0 2-.6 2.4-1.6l4-11.3c7.4 21.8 27.9 35.7 49.8 35.7 5.4 0 11-.9 16.4-2.6 21.9-7.2 36.8-28.4 36.1-51.4-.1-1.5-1.2-2.6-2.6-2.6zM10.3 76c.1 0 0 0 0 0z"></path></svg>
        </div>
                
        <div>
            <svg id="search" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M119.1 113.6L89.4 85.1c8.1-9.5 12.4-21.5 12.1-34.1-.3-13.6-5.9-26.2-15.6-35.6-9.8-9.3-22.7-14.3-36.3-14-13.5.3-26.2 5.9-35.5 15.7-19.4 20.2-18.7 52.4 1.5 71.7 9.8 9.4 22.5 14.1 35.1 14.1 12.5 0 24.9-4.6 34.6-13.6l29.7 28.5c.6.5 1.3.8 2 .8.8 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3 0-4.1zM19.6 84.7C1.7 67.5 1.1 39 18.3 21.1c8.3-8.7 19.5-13.6 31.5-13.9h1c11.6 0 22.6 4.4 31.1 12.5 17.9 17.1 18.5 45.7 1.4 63.6-17.2 17.9-45.7 18.5-63.7 1.4z"></path></svg>
        </div>

        <div>
            <svg id="age-calculator" aria-labelledby="age-calculator" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Age Calculator</title><circle class="st0" cx="45.7" cy="68.3" r="3.2"></circle><path class="st0" d="M22.2 42.9C10.4 42.9.8 33.3.8 21.5S10.4.2 22.2.2s21.4 9.6 21.4 21.4-9.7 21.3-21.4 21.3zm0-40.7c-10.6 0-19.3 8.7-19.3 19.3s8.7 19.3 19.3 19.3c10.6 0 19.3-8.7 19.3-19.3S32.8 2.2 22.2 2.2z"></path><path class="st0" d="M22.2 22.5c-.6 0-1-.5-1-1V9.7c0-.6.5-1 1-1 .6 0 1 .5 1 1v11.8c0 .6-.5 1-1 1z"></path><path class="st0" d="M34 22.5H22.2c-.6 0-1-.5-1-1 0-.6.5-1 1-1H34c.6 0 1 .5 1 1 0 .6-.5 1-1 1z"></path><circle class="st0" cx="75" cy="68.3" r="3.2"></circle><path class="st0" d="M36.7 54.1c-.5 0-1-.4-1.1-.9-.1-.6.3-1.1.9-1.2l17-2.3c.6-.1 1.1.3 1.2.9.1.6-.3 1.1-.9 1.2l-17 2.3h-.1zM83.8 54.1h-.1l-17-2.3c-.6-.1-1-.6-.9-1.2.1-.6.6-1 1.2-.9L84 52c.6.1 1 .6.9 1.2-.1.5-.5.9-1.1.9z"></path><path class="st0" d="M60.3 120c-18 0-35.2-13.6-44.1-34.8-5.7-2-10.6-7.2-12.8-13.7-3.5-10.2.6-22.2 8.7-25.9l1.8-5.3 4 1.4-2.5 7.1-1 .3c-6.5 2.3-9.9 12.5-7 20.8 1.9 5.6 6.1 10 11 11.3l1 .3.4 1c8.1 20.1 24 33 40.5 33 16.4 0 32.3-12.9 40.4-32.8l.4-1 1.1-.2c5-1.1 9.6-5.7 11.6-11.5 2.8-8.1.2-14.9-6.7-17.3-1.3-.4-1.5-1.5-1.7-2.8h-.1l-.2-1.4C101.5 22.9 82.6 4.3 60.3 4.3 53 4.3 46 6.4 39.5 10.5l-2.3-3.6C44.3 2.4 52.3 0 60.3 0c24.4 0 45 20.1 49 47.8.2.4.2 1 .3 1.5 8.4 3.5 11.7 12.3 8.2 22.3-2.4 6.9-7.5 12.2-13.6 14-8.8 20.9-26 34.4-43.9 34.4zm48.2-71.2z"></path></svg>
        </div>

        <div>
            <svg id="care-calculator" aria-labelledby="care-calculator" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Care Calculator</title><path class="st0" d="M60 88.3c-.5 0-1-.2-1.4-.5-1.4-1.1-34.2-26.1-43-44-.6-1.2-6.3-12.9 0-26.7C24.4-1.6 38.5-.7 44.1.6 50.8 2.1 56.8 6.3 60 11.1c3.2-4.8 9.2-9 15.9-10.6C81.5-.8 95.5-1.7 104.4 17c6.3 13.8.6 25.4-.1 26.7-8.8 17.9-41.6 42.9-43 44-.3.4-.8.6-1.3.6zM38.7 4.6c-5.8 0-13.4 2.7-19 14.5-5.3 11.8-.6 21.6 0 22.6C27.1 56.7 53.6 78 60 83.1c6.4-5 32.9-26.4 40.3-41.3.6-1.1 5.3-10.9 0-22.7C93.1 4 82.6 3.8 76.9 5.1c-8.2 1.9-14.6 8.5-14.6 13 0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3c0-4.5-6.4-11.1-14.6-13-1.3-.3-2.8-.5-4.4-.5zM59.5 120c-18.7 0-38.6-4.1-38.6-11.6s19.9-11.6 38.6-11.6c18.7 0 38.6 4.1 38.6 11.6S78.2 120 59.5 120zm0-21c-21.5 0-36.4 5-36.4 9.4s14.9 9.4 36.4 9.4 36.4-5 36.4-9.4S81 99 59.5 99z"></path><path class="st0" d="M59.5 113c-7.9 0-22.8-1.2-22.8-5.9s14.9-5.9 22.8-5.9 22.8 1.2 22.8 5.9-14.9 5.9-22.8 5.9zm0-9.6c-13.4 0-20.5 2.6-20.6 3.7.1 1.1 7.2 3.7 20.6 3.7s20.5-2.6 20.6-3.7c0-1.1-7.2-3.7-20.6-3.7z"></path></svg>
        </div>
        
        <div>
            <svg id="dependency-multiplicand" aria-labelledby="dependency-multiplicand" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Dependency Multiplicand</title><path class="st0" d="M43.6 30.5H2c-1.1 0-2-.9-2-2s.9-2 2-2h41.6c1.1 0 2 .9 2 2s-.9 2-2 2z"></path><path class="st0" d="M22.8 51.3c-1.1 0-2-.9-2-2V7.7c0-1.1.9-2 2-2s2 .9 2 2v41.6c0 1.1-.9 2-2 2zM111.9 114.3c-.5 0-1-.2-1.4-.6L81.1 84.3c-.8-.8-.8-2 0-2.8s2-.8 2.8 0l29.4 29.4c.8.8.8 2 0 2.8-.4.4-.9.6-1.4.6z"></path><path class="st0" d="M82.5 114.3c-.5 0-1-.2-1.4-.6-.8-.8-.8-2 0-2.8l29.4-29.4c.8-.8 2-.8 2.8 0s.8 2 0 2.8l-29.4 29.4c-.4.4-.9.6-1.4.6zM8.1 114.3c-.5 0-1-.2-1.4-.6-.8-.8-.8-2 0-2.8l29.4-29.4c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L9.5 113.7c-.4.4-.9.6-1.4.6zM118 30.5H76.4c-1.1 0-2-.9-2-2s.9-2 2-2H118c1.1 0 2 .9 2 2s-.9 2-2 2z"></path></svg>
        </div>
        
        <div>
            <svg id="earnings" aria-labelledby="earnings" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Earnings Calculator</title><path class="st0" d="M43.4 81.7c-1.2 0-2.1-1-2.1-2.1V29.7c0-10.1 2.3-17.9 6.8-23C52 2.3 57.4 0 63.9 0c13.2 0 27.7 10 27.7 19 0 1.2-1 2.1-2.1 2.1s-2.1-1-2.1-2.1c0-6.1-12.1-14.8-23.4-14.8-3.6 0-8.8.9-12.6 5.3-3.8 4.3-5.7 11.1-5.7 20.2v49.8c-.2 1.2-1.1 2.2-2.3 2.2z"></path><path class="st0" d="M89.5 82.6c-.1 0-.1 0 0 0l-58.9-.9c-1.2 0-2.1-1-2.1-2.2 0-1.2 1-2.1 2.1-2.1l58.9.9c1.2 0 2.1 1 2.1 2.2 0 1.1-1 2.1-2.1 2.1zM65.9 46.2l-35.4-.9c-1.2 0-2.1-1-2.1-2.2 0-1.2.9-2.2 2.2-2.1l35.3.9c1.2 0 2.1 1 2.1 2.2 0 1.1-.9 2.1-2.1 2.1z"></path><g><path class="st0" d="M60 120c-19.4 0-40.1-4.2-40.1-12S40.5 96 60 96s40.1 4.2 40.1 12-20.7 12-40.1 12zm0-21.8c-22.3 0-37.9 5.2-37.9 9.8 0 4.7 15.6 9.8 37.9 9.8s37.9-5.2 37.9-9.8c0-4.6-15.6-9.8-37.9-9.8z"></path></g><g><path class="st0" d="M60 112.8c-8.2 0-23.6-1.3-23.6-6.1s15.5-6.1 23.6-6.1c8.2 0 23.6 1.3 23.6 6.1s-15.4 6.1-23.6 6.1zm0-10c-14.2 0-21.5 2.8-21.5 3.9 0 1.2 7.3 3.9 21.5 3.9s21.5-2.7 21.5-3.9c0-1.2-7.3-3.9-21.5-3.9z"></path></g></svg>
        </div>
        
        <div>
            <svg id="inflation-calculator" aria-labelledby="inflation-calculator" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Inflation Calculator</title><path class="st0" d="M33.9 120H18.2c-3.5 0-6.4-2.9-6.4-6.4V48.8c0-3.5 2.9-6.4 6.4-6.4h15.7c3.5 0 6.4 2.9 6.4 6.4v64.8c0 3.5-2.9 6.4-6.4 6.4zM18.2 46.7c-1.2 0-2.1 1-2.1 2.1v64.8c0 1.2 1 2.1 2.1 2.1h15.7c1.2 0 2.1-1 2.1-2.1V48.8c0-1.2-1-2.1-2.1-2.1H18.2zM67.9 120H52.1c-3.5 0-6.4-2.9-6.4-6.4V30.4c0-3.5 2.9-6.4 6.4-6.4h15.7c3.5 0 6.4 2.9 6.4 6.4v83.2c0 3.5-2.8 6.4-6.3 6.4zM52.1 28.3c-1.2 0-2.1 1-2.1 2.1v83.2c0 1.2 1 2.1 2.1 2.1h15.7c1.2 0 2.1-1 2.1-2.1V30.4c0-1.2-1-2.1-2.1-2.1H52.1zM101.8 120H86.1c-3.5 0-6.4-2.9-6.4-6.4V6.4c0-3.5 2.9-6.4 6.4-6.4h15.7c3.5 0 6.4 2.9 6.4 6.4v107.3c-.1 3.4-2.9 6.3-6.4 6.3zM86.1 4.2c-1.2 0-2.1 1-2.1 2.1v107.3c0 1.2 1 2.1 2.1 2.1h15.7c1.2 0 2.1-1 2.1-2.1V6.4c0-1.2-1-2.1-2.1-2.1H86.1z"></path></svg>
        </div>
        
        <div>
            <svg id="interest" aria-labelledby="interest" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Interest Calculator</title><path class="st0" d="M12.2 120c-.5 0-1-.2-1.4-.5-.9-.8-1-2.1-.3-3L104.3 4.6c.8-.9 2.1-1 3-.3.9.8 1 2.1.3 3L13.8 119.2c-.4.5-1 .8-1.6.8zM30.8 41.6C19.3 41.6 10 32.3 10 20.8 10.1 9.3 19.4 0 30.8 0s20.8 9.3 20.8 20.8c0 11.4-9.3 20.8-20.8 20.8zm0-37.3c-9.1 0-16.5 7.4-16.5 16.5s7.4 16.5 16.5 16.5 16.5-7.4 16.5-16.5S39.9 4.3 30.8 4.3zM89.2 120c-11.5 0-20.8-9.3-20.8-20.8s9.3-20.8 20.8-20.8S110 87.7 110 99.2 100.6 120 89.2 120zm0-37.3c-9.1 0-16.5 7.4-16.5 16.5s7.4 16.5 16.5 16.5 16.5-7.4 16.5-16.5-7.4-16.5-16.5-16.5z"></path></svg>
        </div>
        
        <div>
            <svg id="interpolated" aria-labelledby="interpolated" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Interpolated Multipliers</title><path class="st0" d="M100.8 77.8V42.4c10.7-1.1 19-10.1 19-21.1C119.8 9.6 110.3.1 98.6.1c-11 0-20 8.4-21.1 19H42.4C41.4 8.5 32.4 0 21.4 0 9.7 0 .2 9.5.2 21.2c0 11 8.4 20 19 21.1v34.1C8.5 77.5.2 86.5.2 97.5c0 11.7 9.5 21.2 21.2 21.2 10.5 0 19.2-7.7 20.9-17.8h35.3c-.1-.6-.1-1.3-.1-1.9 0 11.7 9.5 21.2 21.2 21.2s21.2-9.5 21.2-21.2c-.1-11.1-8.5-20.2-19.1-21.2zM98.6 4.4c9.3 0 16.9 7.6 16.9 16.9 0 9.3-7.6 16.9-16.9 16.9-9.3 0-16.9-7.6-16.9-16.9 0-9.3 7.6-16.9 16.9-16.9zM4.4 21.2c0-9.3 7.6-16.9 16.9-16.9 9.3 0 16.9 7.6 16.9 16.9s-7.6 16.9-16.9 16.9c-9.3 0-16.9-7.6-16.9-16.9zm17 93.1c-9.3 0-16.9-7.6-16.9-16.9 0-9.3 7.6-16.9 16.9-16.9 9.3 0 16.9 7.6 16.9 16.9 0 9.3-7.6 16.9-16.9 16.9zm21.1-17.8c-.4-10.6-8.6-19.2-19-20.2V42.2c9.9-1 17.8-8.9 18.9-18.8h35.2c1 10 9 17.9 18.9 18.9v35.4c-9.9 1-17.8 8.9-18.9 18.7H42.5zm56.1 19.3c-9.3 0-16.9-7.6-16.9-16.9S89.3 82 98.6 82c9.3 0 16.9 7.6 16.9 16.9s-7.5 16.9-16.9 16.9z"></path><path class="st0" d="M22.9 97.3l6.8-6.8c.4-.4.4-1.1 0-1.5-.4-.4-1.1-.4-1.5 0l-6.8 6.8-6.9-6.8c-.4-.4-1.1-.4-1.5 0s-.4 1.1 0 1.5l6.8 6.8-6.8 6.8c-.4.4-.4 1.1 0 1.5.2.2.5.3.7.3.3 0 .5-.1.7-.3l6.8-6.8 6.8 6.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1.1 0-1.5l-6.5-6.8z"></path></svg>
        </div>
        
        <div>
            <svg id="life-expectancy" aria-labelledby="life-expectancy" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Life Expectancy Calculator</title><path class="st0" d="M27.1 119.2c-.1-.7-2.5-16.3 3-30.6 3.3-8.5 9.2-14 14-18.3 4.2-3.8 7.5-6.8 7.5-10.2s-3.3-6.4-7.5-10.2c-4.8-4.4-10.8-9.8-14-18.3C24.6 17.3 27 1.7 27.1 1l4.3.7c0 .2-2.3 15.2 2.7 28.4C37 37.4 42.3 42.3 47 46.6c4.8 4.3 8.9 8.1 8.9 13.4s-4.1 9.1-8.9 13.4c-4.7 4.3-10 9.1-12.9 16.7-5.1 13.2-2.8 28.2-2.7 28.4l-4.3.7zM92.9 119.2l-4.3-.7c0-.2 2.3-15.2-2.7-28.4C83 82.6 77.7 77.7 73 73.4c-4.8-4.3-8.9-8.1-8.9-13.4s4.1-9.1 8.9-13.4c4.7-4.3 10-9.1 12.9-16.7C91 16.7 88.7 1.6 88.7 1.5L93 .8c.1.7 2.5 16.3-3 30.6-3.3 8.5-9.2 14-14 18.3-4.2 3.8-7.5 6.8-7.5 10.2s3.3 6.4 7.5 10.2c4.8 4.4 10.8 9.8 14 18.3 5.5 14.5 3.1 30.1 2.9 30.8z"></path><path class="st0" d="M101 4.3H19c-1.2 0-2.2-1-2.2-2.2S17.8 0 19 0h82c1.2 0 2.2 1 2.2 2.2s-1 2.1-2.2 2.1z"></path><g><path class="st0" d="M101 120H19c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2h82c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2z"></path></g></svg>
        </div>
        
        <div>
            <svg id="pension" aria-labelledby="pension" role="img" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M28.6 29.2c.2.2.3.4.3.7 0 .3-.1.6-.3.8-.2.2-.5.3-.8.3H14.6c-.4 0-.6-.1-.8-.3-.2-.2-.3-.4-.3-.8 0-.3.1-.6.3-.7s.5-.3.8-.3h2v-7.4h-2.2c-.3 0-.5-.1-.6-.2-.2-.2-.2-.4-.2-.6s.1-.5.2-.6c.2-.2.4-.2.6-.2h2.2v-4.1c0-1.7.5-3.1 1.5-4.1 1-1 2.4-1.5 4.2-1.5 1.2 0 2.3.2 3.4.6 1.1.4 2 1 2.8 1.8.3.3.5.6.5 1 0 .3-.1.5-.2.7-.2.2-.3.3-.6.3-.2 0-.5-.1-.8-.3-1-.8-1.8-1.3-2.6-1.6-.8-.3-1.6-.5-2.4-.5-1 0-1.8.3-2.4.9-.6.6-.9 1.6-.9 2.8v3.9h4.6c.3 0 .5.1.6.2.2.2.2.4.2.6s-.1.5-.2.6c-.2.2-.4.2-.6.2H19v7.4h8.8c.4.1.6.2.8.4z"></path><circle class="st0" cx="45.7" cy="68.3" r="3.2"></circle><path class="st0" d="M22.2 42.9C10.4 42.9.8 33.3.8 21.5S10.4.2 22.2.2s21.4 9.6 21.4 21.4-9.7 21.3-21.4 21.3zm0-40.7c-10.6 0-19.3 8.7-19.3 19.3s8.7 19.3 19.3 19.3 19.3-8.7 19.3-19.3S32.8 2.2 22.2 2.2z"></path><circle class="st0" cx="75" cy="68.3" r="3.2"></circle><path class="st0" d="M36.7 54.1c-.5 0-1-.4-1.1-.9-.1-.6.3-1.1.9-1.2l17-2.3c.6-.1 1.1.3 1.2.9.1.6-.3 1.1-.9 1.2l-17 2.3h-.1zM83.8 54.1l-17.1-2.3c-.6-.1-1-.6-.9-1.2.1-.6.6-1 1.2-.9L84 52c.6.1 1 .6.9 1.2-.1.5-.5.9-1.1.9z"></path><path class="st0" d="M60.3 120c-18 0-35.2-13.6-44.1-34.8-5.7-2-10.6-7.2-12.8-13.7-3.5-10.2.6-22.2 8.7-25.9l1.8-5.3 4 1.4-2.5 7.1-1 .3c-6.5 2.3-9.9 12.5-7 20.8 1.9 5.6 6.1 10 11 11.3l1 .3.4 1c8.1 20.1 24 33 40.5 33 16.4 0 32.3-12.9 40.4-32.8l.4-1 1.1-.2c5-1.1 9.6-5.7 11.6-11.5 2.8-8.1.2-14.9-6.7-17.3-1.3-.4-1.5-1.5-1.7-2.8h-.1l-.2-1.4C101.5 22.9 82.6 4.3 60.3 4.3 53 4.3 46 6.4 39.5 10.5l-2.3-3.6C44.3 2.4 52.3 0 60.3 0c24.4 0 45 20.1 49 47.8.2.4.2 1 .3 1.5 8.4 3.5 11.7 12.3 8.2 22.3-2.4 6.9-7.5 12.2-13.6 14-8.8 20.9-26 34.4-43.9 34.4zm48.2-71.2z"></path></svg>
        </div>
        
        <div>
            <svg id="period-dates" aria-labelledby="period-dates" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Period Between Two Dates</title><path class="st0" d="M100.1 14h-6.4V4.3c0-2.4-1.9-4.3-4.3-4.3H74.1c-2.4 0-4.3 1.9-4.3 4.3V14H50.2V4.3c0-2.4-1.9-4.3-4.3-4.3H30.6c-2.4 0-4.3 1.9-4.3 4.3V14h-6.4C12.8 14 7 19.8 7 26.9V107c0 7.1 5.8 12.9 12.9 12.9H100c7.1 0 12.9-5.8 12.9-12.9V26.9c.1-7.1-5.7-12.9-12.8-12.9zm-26-9.7h15.2v25.6H74.1V4.3zm-43.5 0h15.2v25.6H30.6V4.3zm78.1 102.8c0 4.7-3.9 8.6-8.6 8.6H19.9c-4.7 0-8.6-3.9-8.6-8.6V26.9c0-4.7 3.9-8.6 8.6-8.6h6.4v11.6c0 2.4 1.9 4.3 4.3 4.3h15.2c2.4 0 4.3-1.9 4.3-4.3V18.3h19.6v11.6c0 2.4 1.9 4.3 4.3 4.3h15.2c2.4 0 4.3-1.9 4.3-4.3V18.3h6.4c4.7 0 8.6 3.9 8.6 8.6v80.2z"></path><path class="st0" d="M41.9 71.9H20.8V50.7H42v21.2zm-19-2.2h16.9V52.9H22.9v16.8zM70.6 71.9H49.4V50.7h21.2v21.2zm-19-2.2h16.9V52.9H51.6v16.8zM99.2 71.9H78.1V50.7h21.2v21.2zm-19-2.2h16.9V52.9H80.2v16.8zM41.9 101.8H20.8V80.6H42v21.2zm-19-2.2h16.9V82.8H22.9v16.8zM70.6 101.8H49.4V80.6h21.2v21.2zm-19-2.2h16.9V82.8H51.6v16.8zM99.2 101.8H78.1V80.6h21.2v21.2zm-19-2.2h16.9V82.8H80.2v16.8z"></path></svg>
        </div>
        
        <div>
            <svg id="table-e-f-discounts" aria-labelledby="table-e-f-discounts" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Pre and Post Assessment Discounts</title><path class="st0" d="M43.4 111.4L0 70.2 55.2 12l27.3-3.4 18.2 17.2-2 27.4-.4.5-54.9 57.7zM5.1 70l38.2 36.2 51.8-54.6 1.8-24.4-15.6-14.8L57 15.5 5.1 70zm71.3-24.3c-3.2 0-6.2-1.2-8.6-3.4-2.4-2.3-3.8-5.4-3.9-8.7-.1-3.3 1.1-6.5 3.4-8.9s5.4-3.8 8.7-3.9c3.4-.1 6.5 1.1 8.9 3.4 2.4 2.3 3.8 5.4 3.9 8.7.1 3.3-1.1 6.5-3.4 8.9-2.3 2.4-5.4 3.8-8.7 3.9h-.3zm0-21.3h-.2c-2.4.1-4.5 1-6.2 2.7-1.6 1.7-2.5 3.9-2.4 6.3.1 2.4 1 4.5 2.7 6.2 1.7 1.6 4 2.5 6.3 2.4 2.4-.1 4.5-1 6.2-2.7 1.6-1.7 2.5-3.9 2.4-6.3-.1-2.4-1-4.5-2.7-6.2-1.7-1.5-3.9-2.4-6.1-2.4z"></path><path class="st0" d="M86.8 35.9c-6.3-.2-7.6-2.2-7.6-3.8.1-6.1 19.3-16.1 35.2-16.3 4.4-.1 5.5 1.4 5.6 2.6.6 4.5-10.7 12.6-21.6 15.4l-.2-.8c10.9-2.9 21.4-10.8 20.9-14.4-.2-1.6-2.7-1.8-4.7-1.8-15.7.2-34.2 10.3-34.3 15.4 0 1.7 2.4 2.7 6.7 2.8v.9z"></path></svg>
        </div>

        <div>
            <svg id="arrow" aria-labelledby="arrow" role="img" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M94.3 63.1H25.7c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4h68.7c1.9 0 3.4 1.5 3.4 3.4s-1.6 3.4-3.5 3.4z"></path><path class="st0" d="M94.3 63.1c-.6 0-1.2-.2-1.8-.5L71.4 49c-1.6-1-2-3.1-1-4.6 1-1.6 3.1-2 4.6-1l21.1 13.5c1.6 1 2 3.1 1 4.6-.6 1-1.7 1.6-2.8 1.6z"></path><path class="st0" d="M73.2 77.1c-1.1 0-2.1-.5-2.8-1.5-1-1.5-.6-3.6.9-4.6l21.1-14.1c1.5-1 3.6-.6 4.6.9 1 1.5.6 3.6-.9 4.6l-21 14.2c-.6.4-1.3.5-1.9.5z"></path><g><path class="st0" d="M60 120C26.9 120 0 93.1 0 60S26.9 0 60 0s60 26.9 60 60-26.9 60-60 60zM60 6.7C30.6 6.7 6.7 30.6 6.7 60s23.9 53.3 53.3 53.3 53.3-23.9 53.3-53.3S89.4 6.7 60 6.7z"></path></g></svg>
        </div>

        <div>
            <svg id="email" aria-labelledby="email" role="img" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M111.2 39.2L73 1c-.6-.6-1.4-.9-2.2-1H22.3c-8 0-14.5 6.5-14.5 14.4v91.2c0 7.9 6.5 14.4 14.4 14.4h75.5c7.9 0 14.4-6.5 14.4-14.4v-64c.1-.9-.3-1.8-.9-2.4zm-10.7-1H79c-2.8 0-5.1-2.3-5.1-5.1V11.6l26.6 26.6zm-2.8 75H22.3c-4.2 0-7.6-3.4-7.6-7.6V14.4c0-4.2 3.4-7.6 7.6-7.6h44.8v26.3C67.1 39.6 72.4 45 79 45h26.4v60.6c0 4.2-3.5 7.6-7.7 7.6z"></path></svg>
        </div>

        <div>
            <svg id="dashboard" aria-labelledby="dashboard" role="img" viewBox="0 0 120 120" width="120" height="120"><title>back to mini calculators</title><path class="st0" d="M55.6 55.4H0V.1h55.6v55.3zM4.2 51.2h47.3v-47H4.2v47zM120 55.4H64.4V.1H120v55.3zm-51.4-4.2h47.3v-47H68.6v47zM55.6 119.9H0V64.6h55.6v55.3zm-51.4-4.1h47.3v-47H4.2v47zM120 119.9H64.4V64.6H120v55.3zm-51.4-4.1h47.3v-47H68.6v47z"></path></svg>
        </div>

        <div>
            <svg id="retirement-calculator" aria-labelledby="retirement-calculator" role="img" viewBox="0 0 120 120" width="120" height="120"><title>Retirement Calculator</title><path class="st0" d="M103.3 120c-3.6 0-7.1-1.7-9-4.9L40 26.6c-.4-.6-3.7-5.5-8.6-2.5-5 3-2 8.2-1.7 8.8l4.9 8c3.1 5 1.3 11.7-4 14.9-5.3 3.2-12 1.8-15.1-3.2L8.4 40.8c-.1-.2-11.8-22.5 10.3-36.1 22.1-13.5 37.8 6.1 38 6.3l.1.2 56.5 92.1c1.5 2.4 1.9 5.3 1.1 8.2-.8 2.8-2.6 5.2-5.1 6.7-1.9 1.2-4 1.8-6 1.8zM34.5 18.8c4.3 0 7.6 3.2 9.1 5.5l54.3 88.5c1.8 3 6 3.8 9.2 1.8 1.6-1 2.7-2.5 3.2-4.2.5-1.7.2-3.4-.6-4.8l-56.4-92c-1.2-1.4-14.1-16.5-32.4-5.2C2.4 19.7 11.4 37.5 12 38.8l7.1 11.6c1.8 3 6 3.8 9.2 1.8 3.3-2 4.4-6.1 2.6-9.1l-4.9-8c-1.9-3.2-3.8-10.4 3.1-14.6 2-1.2 3.8-1.7 5.4-1.7z"></path></svg>
        </div>

        <div>
            <svg id="loss-of-pension" aria-labelledby="loss-of-pension" role="img" viewBox="0 0 56.2 56.2"><path d="M23.5 29.6c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z"/><path d="M46 27.2c-.5-1.1-1.4-2-2.6-2.6 0-.1 0-.2-.1-.3C42 15.7 35.6 9.4 28 9.4c-2.6 0-5 .8-7.2 2.1-1.2-1.1-2.8-1.9-4.6-1.9-3.6 0-6.6 2.9-6.6 6.6 0 2.5 1.4 4.7 3.6 5.8-.2.7-.4 1.5-.5 2.2-2.5 1.2-3.6 4.2-2.5 7.4.7 2 2.2 3.7 4 4.3 2.8 6.5 8.1 10.8 13.7 10.8S38.8 42.5 41.6 36c1.9-.6 3.5-2.3 4.2-4.4.8-1.5.8-3.1.2-4.4zm-35.6-11c0-3.3 2.7-5.9 5.9-5.9 3.3 0 5.9 2.7 5.9 5.9s-2.7 5.9-5.9 5.9c-3.3.1-5.9-2.6-5.9-5.9zm3.8 6.2s-.1 0-.1-.1c0 .1 0 .1.1.1zm30.1 8.7c-.6 1.7-1.9 3.1-3.4 3.4l-.5.1-.1.4c-2.5 6.1-7.3 10-12.2 10-5 0-9.8-3.9-12.2-10l-.2-.4-.4-.1c-1.4-.4-2.6-1.7-3.2-3.3-.8-2.4 0-4.7 1.9-5.3l.5-.2.1-.5c.1-.8.3-1.6.5-2.4.4.1.9.1 1.3.1 3.6 0 6.6-2.9 6.6-6.6 0-1.2-.3-2.3-.9-3.3 1.9-1.1 3.9-1.8 6.1-1.8 6.8 0 12.5 5.7 13.6 13.5l.1.6c.1.4.2.7.6.9.9.3 1.6.9 2 1.8.2.8.2 1.9-.2 3.1zM21.5 12.2z"/><path d="M32.6 29.6c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.5-1-1-1zM26 24.8l-5.2.7c-.2 0-.4.3-.4.5s.2.4.4.4h.1l5.2-.7c.2 0 .4-.3.4-.5-.1-.3-.3-.5-.5-.4zM35.4 25.5l-5.2-.7c-.2 0-.5.1-.5.4 0 .2.1.5.4.5l5.2.7h.1c.2 0 .4-.2.4-.4 0-.3-.2-.5-.4-.5zM18.2 19.2c.1-.1.1-.1.1-.2s0-.2-.1-.2c-.1-.1-.1-.1-.2-.1h-2.6v-2.2h1.3c.1 0 .1 0 .2-.1 0 0 .1-.1.1-.2s0-.1-.1-.2c0 0-.1-.1-.2-.1h-1.3v-1.1c0-.4.1-.6.3-.8.2-.2.4-.3.7-.3.2 0 .5 0 .7.1.2.1.5.3.8.5.1.1.2.1.2.1.1 0 .1 0 .2-.1 0-.1.1-.1.1-.2s0-.2-.1-.3c-.2-.2-.5-.4-.8-.5-.3-.1-.6-.2-1-.2-.5 0-.9.1-1.2.4-.3.3-.4.7-.4 1.2V16H14c-.1 0-.1 0-.2.1 0 0-.1.1-.1.2s0 .1.1.2c0 0 .1.1.2.1h.6v2.2H14c-.1 0-.2 0-.2.1-.1.1-.1.1-.1.2s0 .2.1.2c.1.1.1.1.2.1h3.8c.2-.1.3-.2.4-.2z"/><path d="M28.1 0C12.6 0 0 12.6 0 28.1s12.6 28.1 28.1 28.1c15.5 0 28.1-12.6 28.1-28.1S43.6 0 28.1 0zM2 28.1C2 13.7 13.7 2 28.1 2c6.7 0 12.8 2.5 17.4 6.7L9.3 46.2C4.8 41.5 2 35.1 2 28.1zm26.1 26.1c-6.6 0-12.7-2.5-17.3-6.6L46.9 10c4.5 4.7 7.3 11 7.3 18 0 14.5-11.7 26.2-26.1 26.2z"/></svg>
        </div>

        <div>
            <svg id="mesothelioma-psla-calculator" aria-labelledby="mesothelioma-psla-calculator" role="img" viewBox="0 0 55.3 56.2"><circle class="st0" cx="20.9" cy="32" r="1.5"/><path class="st0" d="M10 20.1c-5.5 0-10-4.5-10-10S4.5.1 10 .1s10 4.5 10 10-4.5 10-10 10zM10 1c-5 0-9 4.1-9 9s4.1 9 9 9c5 0 9-4.1 9-9s-4-9-9-9z"/><path class="st0" d="M50.8 23c0-.2-.1-.5-.1-.7C48.8 9.4 39.2 0 27.8 0 24 0 20.4 1.1 17.2 3.1c.5.5.9 1 1.2 1.6C21.3 3 24.4 2 27.8 2c10.4 0 19.3 8.7 21 20.8l.1.6h.1c.1.6.2 1.1.8 1.3 3.2 1.1 4.4 4.3 3.1 8.1-.9 2.7-3.1 4.8-5.4 5.4l-.5.1-.2.5C43 48.1 35.6 54.2 27.9 54.2c-7.7 0-15.2-6.1-18.9-15.5l-.2-.5-.5-.1c-2.3-.6-4.2-2.7-5.1-5.3-1.3-3.9 0-7.5 3.1-8.6l.4-.2.1-.6c.2-1.3.4-2.5.8-3.7-.7-.2-1.3-.4-1.9-.7-.3 1.1-.6 2.3-.8 3.5-3.8 1.7-5.4 6.2-3.7 10.9 1.1 3.1 3.3 5.5 6 6.4 4.1 9.9 12.2 16.3 20.6 16.3 8.4 0 16.4-6.3 20.6-16.2 2.8-.8 5.2-3.3 6.4-6.5 1.5-4.6 0-8.7-4-10.4z"/><circle class="st0" cx="34.6" cy="32" r="1.5"/><path class="st0" d="M16.7 25.3c-.2 0-.5-.2-.5-.4 0-.3.2-.5.4-.6l8-1.1c.3 0 .5.2.6.4 0 .3-.2.5-.4.6l-8.1 1.1zM38.8 25.3c-.1 0-.1 0 0 0l-8-1.1c-.3 0-.5-.3-.4-.6 0-.3.3-.5.6-.4l8 1.1c.3 0 .5.3.4.6-.2.2-.4.4-.6.4zM9.4 15.5c-.2-.2-.3-.4-.3-.6 0-.3.1-.5.3-.7.2-.2.4-.3.6-.3.2 0 .5.1.6.3.2.2.2.4.2.7 0 .3-.1.5-.2.6-.2.2-.4.2-.6.2-.2 0-.5 0-.6-.2zm.3-2.8c-.1-.1-.1-.2-.1-.3l-.4-7.3c0-.2 0-.4.2-.6.2-.1.3-.2.6-.2.2 0 .4.1.6.2.1.1.2.3.2.6l-.4 7.2c0 .1-.1.3-.1.3-.1.1-.2.1-.3.1-.1.1-.2.1-.3 0z"/></svg>
        </div>
        
        <div>
            <svg id="add" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M116.9 56.9H63.1V3.1C63.1 1.4 61.7 0 60 0s-3.1 1.4-3.1 3.1v53.8H3.1C1.4 56.9 0 58.3 0 60c0 1.7 1.4 3.1 3.1 3.1h53.8v53.8c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1V63.1h53.8c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1z"></path></svg>
        </div>

        <div>
            <svg id="arrow-head" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><path fill="none" d="M3.8 25.8L60 94.2l56.2-68.4"/><path d="M60 98c-1.1 0-2.2-.5-2.9-1.4L.9 28.1c-1.3-1.6-1.1-4 .5-5.3 1.6-1.3 4-1.1 5.3.5l53.4 65 53.4-65c1.3-1.6 3.7-1.8 5.3-.5 1.6 1.3 1.8 3.7.5 5.3L62.9 96.6c-.7.9-1.8 1.4-2.9 1.4z" /></svg>
        </div>

        <div>
            <svg id="calendar" viewBox="0 0 120 120"><path d="M43.8 77.7H22.4V56.3h21.4v21.4zM28.2 72h10.4V61.6H28.2V72zM70.4 77.7H49.6V56.3H71v21.4h-.6zM54.8 72h10.4V61.6H54.8V72zM97.6 77.7H76.2V56.3h21.4v21.4zM81.4 72h10.4V61.6H81.4V72zM43.8 104.3H22.4V83h21.4v21.3zm-15.6-5.2h10.4V88.7H28.2v10.4zM70.4 104.3H49.6V83H71v21.4h-.6zm-15.6-5.2h10.4V88.7H54.8v10.4zM97.6 104.3H76.2V83h21.4v21.3zm-16.2-5.2h10.4V88.7H81.4v10.4z"/><path d="M99.1 10.4h-4.7V5.2c0-2.6-2.1-5.2-5.2-5.2H75.7c-2.6 0-5.2 2.1-5.2 5.2v5.2H49.6V5.2c0-2.6-2.1-5.2-5.2-5.2H30.8c-2.6 0-5.2 2.1-5.2 5.2v5.2h-4.2c-8.9 0-16.2 7.3-16.2 16.2v77.7c0 8.9 7.3 15.7 16.2 15.7h77.2c8.9 0 16.2-6.8 16.2-15.7V26.6c0-8.9-7.3-16.2-15.7-16.2zM76.2 5.2h13.6v21.3H76.2V5.2zm-45.4 0h13.6v21.3H30.8V5.2zm78.8 99.1c0 5.7-4.7 10.4-10.4 10.4H21.4c-5.7 0-10.4-4.7-10.4-10.4V42.8h98.6v61.5zm0-66.7H11v-11c0-5.7 4.7-10.4 10.4-10.4h4.2v10.4c0 2.6 2.1 5.2 5.2 5.2h13c3.1 0 5.7-2.1 5.7-5.2V16.2h20.9v10.4c0 2.6 2.1 5.2 5.2 5.2h13.6c3.1 0 5.7-2.1 5.2-5.2V16.2h4.7c5.7 0 10.4 4.7 10.4 10.4v11z"/></svg>
        </div>

        <div>
            <svg id="delete" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M109.1 11.3H76.9V5.6c0-3.1-2.5-5.6-5.6-5.6H48.8c-3.1 0-5.6 2.5-5.6 5.6v5.7H10.9c-1.6 0-2.8 1.3-2.8 2.8 0 1.5 1.3 2.8 2.8 2.8h5.2l3.6 92.9c.2 5.7 4.9 10.1 10.5 10.1h59.5c5.7 0 10.3-4.5 10.5-10.1l3.6-92.9h5.2c1.6 0 2.8-1.3 2.8-2.8.1-1.5-1.2-2.8-2.7-2.8zM48.8 5.6h22.4v5.6H48.8V5.6zm45.9 104c-.1 2.6-2.3 4.7-4.9 4.7H30.3c-2.6 0-4.8-2.1-4.9-4.7l-3.6-92.7h76.4l-3.5 92.7z"></path><path class="st0" d="M42.8 42.6c0-1.5-1.3-2.8-2.8-2.8-1.6 0-2.8 1.3-2.8 2.9l.9 46.1c0 1.5 1.3 2.8 2.8 2.8 1.6 0 2.8-1.3 2.8-2.9l-.9-46.1zM60 39.8c-1.6 0-2.8 1.3-2.8 2.8v46.1c0 1.6 1.3 2.8 2.8 2.8 1.6 0 2.8-1.3 2.8-2.8V42.6c0-1.6-1.2-2.8-2.8-2.8zM80.1 39.8c-1.5 0-2.9 1.2-2.9 2.8l-.9 46.1c0 1.6 1.2 2.8 2.8 2.9 1.5 0 2.8-1.2 2.8-2.8l.9-46.1c.1-1.6-1.1-2.9-2.7-2.9z"></path></svg>
        </div>

        <div>
            <svg id="download" viewBox="0 0 120 120" width="120" height="120"><path class="st0" d="M88.4 69.5c-1.2-1.3-3.2-1.5-4.6-.3L63.2 87.6v-84c0-1.8-1.4-3.2-3.2-3.2-1.8 0-3.2 1.4-3.2 3.2v84L36.1 69.2c-1.3-1.2-3.4-1.1-4.6.3-1.2 1.3-1.1 3.4.3 4.6L55 94.8c2.8 2.5 7.1 2.5 9.9 0L88.2 74c.7-.6 1.1-1.5 1.1-2.4 0-.7-.3-1.5-.9-2.1z"></path><path class="st0" d="M97.5 119.6h-75C10.1 119.6 0 109.5 0 97.1V71.5c0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2v25.7c0 8.8 7.2 16 16 16h75.1c8.8 0 16-7.2 16-16V71.5c0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2v25.7c.1 12.3-10 22.4-22.4 22.4z"></path></svg>
        </div>

    </div>

    <h2 style={{marginTop: `80px`}}>View other pieces of work...</h2>
    <div className="work" style={{display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, alignItems: `center`, marginTop: `40px`}}>
        <a href="/quantum/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-1" style={{height: `300px`}}></div>
        </a>
        <a href="/tim-burton/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-2" style={{height: `300px`}}></div>
        </a>
        <a href="/otis/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-5" style={{height: `300px`}}></div>
        </a>
    </div>
    <Link className="back-link" to="/" style={{marginTop: `20px`, display: `block`}}>Go back to the homepage</Link>

  </Layout>
)

export default IconsPage

export const pageQuery = graphql`
  query {
    imageIconsOne: file(relativePath: { eq: "icons-header.png" }) {
      ...fluidImage
    }
  }
`